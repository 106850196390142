@import "tailwindcss/base";
@import "tailwindcss/components";
@import "tailwindcss/utilities";

.App {
    text-align: center;
  }
  
  .App-logo {
    height: 40vmin;
    pointer-events: none;
  }
  
  @media (prefers-reduced-motion: no-preference) {
    .App-logo {
      animation: App-logo-spin infinite 20s linear;
    }
  }
  
  .App-header {
    background-color: #282c34;
    min-height: 100vh;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    font-size: calc(10px + 2vmin);
    color: white;
  }
  
  .App-link {
    color: hsl(193, 95%, 68%);
  }
  
  @keyframes App-logo-spin {
    from {
      transform: rotate(0deg);
    }
    to {
      transform: rotate(360deg);
    }
  }
  
  .small {
    width: 100px;
    height: 100px;
    border: 15px solid rgb(0 4 245);
  }
  .tiny {
    width: 50px;
    height: 50px;
    border: 10px solid white;
  }
  .large {
    width: 200px;
    height: 200px;
    border: 20px solid rgb(0 4 245);
  }
  .lds-dual-ring {
    content: ' ';
    display: block;
    border-radius: 50%;
    animation: lds-dual-ring 1.2s linear infinite;
  }
  .white {
    border-color: white transparent white transparent;
  }
  .blue {
    border-color: rgb(0 4 245) transparent rgb(0 4 245) transparent;
  }
  
  @keyframes lds-dual-ring {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(360deg);
    }
  }
  
  .collapsible {
    color: #444;
    cursor: pointer;
    padding: 18px;
    width: 100%;
    border: none;
    text-align: left;
    outline: none;
    font-size: 15px;
  }
  
  .collapsible-content {
    padding: 0 18px;
    background-color: white;
    max-height: 0;
    overflow: hidden;
    transition: max-height 0.2s ease-out;
  }
  
  body {
    height: 100vh;
  }